.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  padding-top: 1px;
}

.formMargins {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.title {
  margin-bottom: 24px;
}

.error {
  color: var(--failColor);
}

.allowInstantBookingsBlock {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    flex-direction: row;
    margin-bottom: 16px;
  }
}

.allowInstantBookingsSubtitle {
  margin-top: 16px;
  font-size: 18px;
  font-weight: 600;
}

.allowInstantBookingsRadiobutton {
  margin-bottom: 0;

  @media (--viewportMedium) {
    composes: formMargins;
    flex-shrink: 0;
    margin-right: 30px;
  }
}

.tenantSpaceTypeBlock {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    flex-direction: row;
    margin-bottom: 16px;
  }
}

.tenantSpaceTypeSubtitle {
  margin-top: 16px;
  font-size: 18px;
  font-weight: 600;
}

.tenantSpaceTypeRadiobutton {
  margin-bottom: 0;

  @media (--viewportMedium) {
    composes: formMargins;
    flex-shrink: 0;
    margin-right: 30px;
  }
}

.minBookingPeriodSubtitle {
  margin-top: 16px;
  font-size: 18px;
  font-weight: 600;
}

.calendarWrapper {
  flex-grow: 1;
  position: relative;
  width: 100%;
  margin-bottom: 24px;
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;
}

@media (--viewportMedium) {
  .root {
    padding-top: 2px;
    margin-top: -16px;
  }
  .title {
    margin-bottom: 32px;
  }
}

@media (--viewportLarge) {
  .calendarWrapper {
    flex-grow: 0;
    margin-bottom: 24px;
  }
  .submitButton {
    display: inline-block;
    width: 241px;
    margin-top: 86px;
  }
}
