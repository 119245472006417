.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.formMargins {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.policyField {
  width: 100%;
  margin: 0;
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}

.exampleBlock {
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  @media (--viewportMedium) {
    flex-direction: row;
    margin-top: 25px;
  }
}

.exampleBlockTitle {
  font-size: 16px;
  margin-top: 20px;

  @media (--viewportMedium) {
    font-size: 18px;
    margin-top: 25px;
  }
}

.nightlyExampleBeforeTwoWeeksBlock {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;

  @media (--viewportMedium) {
    width: 34%;
    margin-bottom: 25px;
  }
}

.exampleBeforeTwoWeeksLabel {
  font-size: 14px;
  font-wieght: 600;
  text-align: center;

  @media (--viewportMedium) {
    font-size: 16px;
  }
}

.exampleBeforeTwoWeeksColorHeader {
  background-color: #4AB153;
}

.nightlyExampleMoveInBlock {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;

  @media (--viewportMedium) {
    width: 33%;
    margin-bottom: 25px;
  }
}

.monthlyExampleMoveInBlock {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;

  @media (--viewportMedium) {
    width: 50%;
    margin-bottom: 25px;
  }
}

.exampleMoveInLabel {
  font-size: 14px;
  font-wieght: 600;
  text-align: center;

  @media (--viewportMedium) {
    font-size: 16px;
  }
}

.exampleMoveInColorHeader {
  background-color: #FDB327;
}

.exampleMoveInDate {
  font-size: 14px;
  font-wieght: 400;
  text-align: center;

  @media (--viewportMedium) {
    font-size: 16px;
  }
}

.exampleMoveInTime {
  font-size: 14px;
  font-wieght: 400;
  text-align: center;
  margin-bottom: 20px;

  @media (--viewportMedium) {
    font-size: 16px;
    margin-bottom: 25px;
  }
}

.exampleMoveInDescription {
  font-size: 14px;
  font-wieght: 400;
  text-align: justify;
  padding: 15px;

  @media (--viewportMedium) {
    font-size: 16px;
    margin-bottom: 25px;
    padding: 20px;
  }
}

.nightlyExampleMoveOutBlock {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;

  @media (--viewportMedium) {
    width: 33%;
    margin-bottom: 25px;
  }
}

.monthlyExampleMoveOutBlock {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;

  @media (--viewportMedium) {
    width: 50%;
    margin-bottom: 25px;
  }
}

.exampleMoveOutLabel {
  font-size: 14px;
  font-wieght: 600;
  text-align: center;

  @media (--viewportMedium) {
    font-size: 16px;
  }
}

.exampleMoveOutColorHeader {
  background-color: #FB5D62;
}

.exampleMoveOutDate {
  font-size: 14px;
  font-wieght: 400;
  text-align: center;

  @media (--viewportMedium) {
    font-size: 16px;
  }
}

.exampleMoveOutTime {
  font-size: 14px;
  font-wieght: 400;
  text-align: center;
  margin-bottom: 20px;

  @media (--viewportMedium) {
    font-size: 16px;
    margin-bottom: 25px;
  }
}

.exampleMoveOutDescription {
  font-size: 14px;
  font-wieght: 400;
  text-align: justify;
  padding: 15px;

  @media (--viewportMedium) {
    font-size: 16px;
    margin-bottom: 25px;
    padding: 20px;
  }
}
