@import '../../styles/propertySets.css';

.title {
  @apply --marketplaceH1FontStyles;
  margin-top: 0;
}

.subtitle {
  @apply --marketplaceH2FontStyles;
  font-weight: 700;
  margin-top: 0;
}

.registeredSign {
  color: red;
}

.steps {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.step {
  text-align: center;
  @apply --marketplaceH2FontStyles;
  margin-top: 0;
  width: 33%;

  @media (--viewportMedium) {
    width: 324px;
    margin-right: 40px;
  }
}

.step:last-of-type {
  @media (--viewportMedium) {
    margin-right: 0;
  }
}

.stepTitle {
  @apply --marketplaceH1FontStyles;
  margin-top: 0;
  margin-bottom: 18px;
  font-weight: 700;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 18px;
  }
}

.stepText {
  font-weight: 700;
}

.crmDemo {
  text-align: center;
  margin-bottom: 40px;
}

.crmImage {
  max-width: 100%;
  outline: none;
  border-color: #9e9e9e;
  box-shadow: 0 0 10px #9e9e9e;
}

.createListingLink {
  margin-top: 0;
  text-align: center;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}
