@import '../../styles/propertySets.css';

.root {
  display: flex;
  flex-direction: column;
}

.bookingDates {
  flex-shrink: 0;
  margin-bottom: 20px;

  /* Ensure that calendar dropdown gets some stacking context relative to other form items below */
  z-index: 1;
}

.tenantsField {
  flex-shrink: 0;
  margin: 0 24px 20px 24px;

  @media(--viewportMedium){
    margin: 0 0 20px 0;
  }
}

.childrenRow {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin: 0 24px 20px 24px;

  @media(--viewportMedium){
    flex-direction: row;
    margin: 0 0 20px 0;
  }
}

.childrenField {
  margin-bottom: 20px;

  @media(--viewportMedium) {
    margin-right: 10px;
    margin-bottom: 0;
  }
}

.infantsField {
  @media(--viewportMedium) {
    margin-left: 10px;
  }
}

.priceBreakdownContainer {
  padding: 0 24px;
  margin-bottom: 40px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
    margin-top: 1px;
  }
}

.priceBreakdownTitle {
  /* Font */
  color: var(--matterColorAnti);

  margin-top: 5px;
  margin-bottom: 14px;
  @media (--viewportMedium) {
    margin-top: 5px;
    margin-bottom: 26px;
  }
}

.receipt {
  flex-shrink: 0;
  margin: 0 0 24px 0;
}

.error {
  color: var(--failColor);
  margin: 0 24px;
  display: inline-block;
}

.spinner {
  margin: auto;
}

.sideBarError {
  @apply --marketplaceH4FontStyles;
  color: var(--failColor);
  margin: 0 24px 12px 24px;

  @media (--viewportMedium) {
    margin: 0 0 12px 0;
  }
}

.smallPrint {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  text-align: center;
  margin: auto 24px 20px 24px;
  flex-shrink: 0;

  @media (--viewportMedium) {
    margin-top: auto;
    margin-bottom: 20px;
  }

  @media (--viewportLarge) {
    margin-top: 4px;
    margin-bottom: 21px;
  }
}

.watchersBlock {
  display: flex;
  flex-direction: row;
  margin: auto 24px 20px 24px;
  flex-shrink: 0;
  width: 100%;

  @media (--viewportMedium) {
    margin-top: auto;
    margin-bottom: 20px;
  }

  @media (--viewportLarge) {
    margin-top: 4px;
    margin-bottom: 21px;
  }
}

.watchersTextWrapper {
  width: calc( 90% - 48px );
}

.watchersNote {
  @apply --marketplaceTinyFontStyles;
  color: black;
  font-weight: 600;
  text-align: left;
  margin: 0;
  flex-shrink: 0;
}

.watchersCount {
  @apply --marketplaceTinyFontStyles;
  color: black;
  text-align: left;
  margin: 0;
  flex-shrink: 0;
}

.watchersIconWrapper {
  text-align: right;
  width: 10%;
}

.iconEye {
  margin: auto;
}

.submitButtonWrapper {
  flex-shrink: 0;
  padding: 0 24px 24px 24px;
  width: 100%;

  @media (--viewportMedium) {
    padding: 0;
    width: 100%;
  }
}
