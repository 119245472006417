@import '../../styles/propertySets.css';

.root {
  position: relative;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (--viewportLarge) {
    flex-direction: row;
    max-width: 1128px;
    margin: 0 auto 57px auto;
    padding: 0 36px;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

.txInfo {
  margin-bottom: 210px;

  @media (--viewportLarge) {
    flex-basis: 538px;
    flex-grow: 0;
    flex-shrink: 1;
    margin-right: 56px;
    margin-bottom: 0;
  }
}

.genericError {
  color: var(--failColor);
  margin: 24px 24px 0 24px;

  @media (--viewportMedium) {
    margin: 32px 24px 0 24px;
  }
  @media (--viewportLarge) {
    margin: 32px 0 0 0;
  }
}

/* DetailCardImage subcomponent */
.detailCardImageWrapper {
  /* Layout */
  display: none;
  width: 100%;
  position: relative;

  @media (--viewportLarge) {
    display: block;
    max-height: 268px;
    overflow-y: hidden;
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  background-color: var(--matterColorNegative); /* Loading BG color */
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;

  @media (--viewportLarge) {
    border-radius: 2px 2px 0 0;
  }
}

.avatarWrapper {
  /* Position (over the listing image)*/
  margin-left: 24px;
  margin-top: -30px;

  /* Rendering context to the same lavel as listing image */
  position: relative;
  /* Flex would give too much width by default. */
  width: 60px;

  @media (--viewportMedium) {
    margin-top: -32px;
    padding: 2px 0;
  }
  @media (--viewportLarge) {
    margin-left: 48px;
    width: unset;
    padding: 2px 0;
  }
}

/* Passed-in props for DetailCardImage subcomponent */
.imageWrapperMobile {
  position: relative;

  @media (--viewportLarge) {
    display: none;
  }
}

.avatarWrapperMobile {
  composes: avatarWrapper;

  @media (--viewportLarge) {
    display: none;
  }
}

.avatarWrapperDesktop {
  composes: avatarWrapper;
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

.avatarWrapperProviderDesktop {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin-top: 119px;
  }
}
.avatarDesktop {
  @media (--viewportLarge) {
    display: flex;
  }
}

/* PanelHeadings subcomponent */
.headingOrder {
  margin: 29px 24px 0 24px;

  @media (--viewportMedium) {
    max-width: 80%;
    margin: 24px 24px 0 24px;
    padding: 1px 0 7px 0;
  }

  @media (--viewportLarge) {
    max-width: 100%;
    margin: 24px 0 0 0;
    padding: 0;
  }
}

.headingSale {
  margin: 18px 24px 0 24px;
  padding: 5px 0 1px 0;

  @media (--viewportMedium) {
    max-width: 80%;
    margin: 24px 24px 0 24px;
    padding: 1px 0 7px 0;
  }

  @media (--viewportLarge) {
    max-width: 100%;
    margin: 42px 0 0 0;
    padding: 0;
  }
}

.mainTitle {
  display: block;
  font-size: 28px;

  @media (--viewportMedium) {
    font-size: 32px;
  }
}

.transactionInfoMessage {
  margin: 18px 24px 0 24px;

  @media (--viewportMedium) {
    margin: 23px 24px 0 24px;
  }
  @media (--viewportLarge) {
    margin: 23px 0 0 0;
  }
}

.bidStatsRow {
  display:flex;
  flex-direction: row;
  margin-top: 20px;
  margin-left: 24px;
  margin-right: 24px;

  @media (--viewportMedium) {
    flex-direction: row;
    margin-left: 0;
    margin-right: 0;
  }

  @media (--viewportLarge) {
    flex-direction: row;
    max-width: 1128px;
    margin: 30px auto 0 auto;
    padding: 0 36px;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

.bidStatsCell {
  display:flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 20px;

  @media (--viewportMedium) {
    width: 33%;
    font-size: 36px;
  }
}

.bidStatsValue {
  display:flex;
  flex-direction: row;
  margin: 10px auto;

  @media (--viewportMedium) {
    margin: 0 auto;
  }
}

.bidStatsFigure {
  font-size: 28px;
  color: #E5242B;

  @media (--viewportMedium) {
    font-size: 28px;
  }
}

.bidStatsExplanation {
  font-size: 14px;
  font-weight: 600;

  @media (--viewportMedium) {
    font-size: 16px;
    font-weight: 800;
  }
}

.bidStatsUnit {
  font-size: 12px;
  font-weight: 800;
}

.otherOffersLink {
  display:flex;
  flex-direction: column;
  margin: 30px 24px 20px 24px;
  text-align: center;
  color: var(--marketplaceColor);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0 36px;
    margin: 30px auto 20px auto;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
  }
}

.otherOffersBlock {
  display:flex;
  flex-direction: column;
  padding: 10px;
  margin: 30px 24px 20px 24px;
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 10px 36px;
    margin: 30px auto 20px auto;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
  }
}

.otherOfferRow {
  display:flex;
  flex-direction: column;
  width: 100%;
  padding: 5px 0;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.otherOfferAvatar {
  display:flex;
  flex-direction: column;
  width: 100%;
  text-align: center;

  @media (--viewportMedium) {
    flex-direction: row;
    width: 20%;
  }
}

.otherOfferAvatarWrapper {
  padding: 0 auto;
  text-align: center;
}

.otherOfferName {
  display:flex;
  flex-direction: column;
  width: 100%;
  text-align: center;

  @media (--viewportMedium) {
    flex-direction: row;
    width: 70%;
  }
}

.otherOfferAmount {
  display:flex;
  flex-direction: column;
  width: 100%;
  text-align: center;

  @media (--viewportMedium) {
    flex-direction: row;
    width: 10%;
  }
}

.otherOfferLink {
  display:flex;
  flex-direction: column;
  width: 100%;
  text-align: center;

  @media (--viewportMedium) {
    padding-left: 35px;
    flex-direction: row;
    width: 150px;
  }
}


/* Container for booking details in mobile layout */
.bookingDetailsMobile {
  margin-top: 47px;

  @media (--viewportMedium) {
    margin-top: 40px;
    padding: 4px 0 0px 0;
  }
  @media (--viewportLarge) {
    display: none;
  }
}

/* "aside" section in desktop layout */
.asideDesktop {
  margin: 1px 0 0 0;

  /**
   * Aside is visible on mobile layout too, since it includes BookingPanel component.
   * It might get rendered as a Modal in mobile layout.
   */

  @media (--viewportLarge) {
    margin: 23px 0 0 0;
  }
}

.detailCard {
  @media (--viewportLarge) {
    position: sticky;
    top: -200px; /* This is a hack to showcase how the component would look when the image isn't sticky */
    width: 633px;
    background-color: var(--matterColorLight);
    border: 1px solid var(--matterColorNegative);
    border-radius: 2px;
    z-index: 1;
  }
}

/* DetailCardHeadingsMaybe subcomponent */
.detailCardHeadings {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin: 0 48px 33px 48px;
  }
}

.detailCardTitle {
  margin-bottom: 10px;

  @media (--viewportLarge) {
    margin-top: 14px;
    margin-bottom: 0;
  }
}

.detailCardSubtitle {
  @apply --marketplaceH5FontStyles;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-top: 9px;
    margin-bottom: 0;
  }
}

/* AddressLinkMaybe subcomponent */
.address {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColorAnti);
  margin: 0;
}

/* Passed-in props for AddressLinkMaybe subcomponent */
.addressMobile {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColorAnti);
  margin: 0 24px 24px 24px;
}

/* BreakdownMaybe subcomponent */
.breakdownMaybe {
  /* default "root" class for breakdown container */
}

.bookingBreakdownTitle {
  /* Font */
  color: var(--matterColorAnti);

  margin: 0 24px 0 24px;

  @media (--viewportLarge) {
    margin: 37px 48px 26px 48px;
    margin: 32px 48px 24px 48px;
    padding: 4px 0 4px 0;
  }
}

.breakdown {
  margin: 14px 24px 0 24px;

  @media (--viewportMedium) {
    margin: 18px 24px 0 24px;
  }
  @media (--viewportLarge) {
    margin: 24px 48px 47px 48px;
    padding: 6px 0 2px 0;
  }
}

.breakdownContainer {
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

/* FeedSection subcomponent */
.feedHeading {
  color: var(--matterColorAnti);
  margin: 0;

  @media (--viewportMedium) {
    margin: 0;
  }
}

.feed {
  margin-top: 20px;
}

.messageError {
  color: var(--failColor);
  margin: 13px 0 22px 0;

  @media (--viewportMedium) {
    margin: 13px 0 23px 0;
  }

  @media (--viewportLarge) {
    margin: 12px 0 23px 0;
  }
}

.solanaPaymentBlock {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 16px;
  border: 1px solid var(--matterColorNegative);
}

.solanaPaymentTitle {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;

  @media (--viewportMedium) {
    font-size: 24px;
  }
}

.solanaPaymentError {
  margin-top: 15px;
  color: red;
}

.fundBookingButton {
  margin-top: 20px;
  margin-bottom: 20px;
}

.noPaypentButtonMessage {
  margin-top: 15px;
  color: blue;
}

/* Passed-in props for FeedSection subcomponent */
.feedContainer {
  margin: 46px 24px 0 24px;

  @media (--viewportMedium) {
    margin: 40px 24px 0 24px;
  }
  @media (--viewportLarge) {
    margin: 43px 0 0 0;
  }
}

.messagingIsLocked {
  margin-top: 20px;
  color: blue;
}

.acceptanceIsLocked {
  margin-top: 20px;
  color: red;
}

/* Prop to be passed to SendMessageForm component */
.sendMessageForm {
  position: relative;
  margin: 46px 24px 0 24px;

  @media (--viewportMedium) {
    margin: 49px 24px 0 24px;
    border-top: 0;
  }
  @media (--viewportLarge) {
    margin: 47px 0 0 0;
  }
}

.sendingMessageNotAllowed {
  color: var(--matterColorAnti);

  position: relative;
  margin: 46px 24px 0 24px;

  @media (--viewportMedium) {
    margin: 49px 24px 0 24px;
    border-top: 0;
  }
  @media (--viewportLarge) {
    margin: 47px 0 0 0;
  }
}

/* SaleActionButtonsMaybe subcomponent */
.actionButtons {
  /* Position action button row above the footer */
  z-index: 9;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 18px 24px 18px 24px;

  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);

  box-shadow: var(--boxShadowTop);
  background-color: white;

  @media (--viewportLarge) {
    z-index: unset;
    position: static;
    box-shadow: none;
    width: auto;
    margin: 0 48px 0 48px;
    padding: 0;
  }
}

.actionButtonWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;

  @media (--viewportLarge) {
    flex-direction: column;
  }

  & button:first-child {
    margin: 0 12px 0 0;

    @media (--viewportLarge) {
      margin: 8px 0 0 0;

      /* Switch order in desktop layout with accept button being on the top */
      order: 1;
    }
  }
}

.paymentInfo {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColorAnti);
  text-align: center;
  padding: 0 42px;
  margin: 28px 0 0 0;

  @media (--viewportLarge) {
    /* TODO this is ugly overwrite to fix unconsistent font styles */
    font-size: 14px;
    padding: 0;
  }
}

.cancellationLockWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  font-weight: bold;
  text-align: center;

  @media (--viewportLarge) {
    flex-direction: column;
  }

  & button:first-child {
    margin: 0 12px 0 0;

    @media (--viewportLarge) {
      margin: 8px 0 0 0;

      /* Switch order in desktop layout with accept button being on the top */
      order: 1;
    }
  }
}

.actionError {
  @apply --marketplaceH5FontStyles;
  color: var(--failColor);
  margin: 0 0 11px 0;

  @media (--viewportMedium) {
    margin: 0 0 10px 0;
  }
  @media (--viewportLarge) {
    margin: 0;
  }
}

.actionErrors {
  width: 100%;
  text-align: center;

  @media (--viewportLarge) {
    position: absolute;
    top: 151px;
  }
}

/* Container for SaleActionButtonsMaybe subcomponent */
.mobileActionButtons {
  display: block;

  @media (--viewportLarge) {
    display: none;
  }
}

.desktopActionButtons {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin-bottom: 48px;
  }
}

.cancellationReasonModalContent {
  flex-grow: 1;
  display: flex;
}

.cancellationReasonForm {
  flex-grow: 1;
  margin: 70px 0 100px 0;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    margin: 0;
  }
}

.cancellationReasonSubmitButtonWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 24px;
  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    position: static;
    padding: 0;
    margin-top: auto;
  }
}


/* BookingPanel subcompnent */
.bookingPanel {
  margin: 16px 48px 48px 48px;
}

.bookingTitle {
  /* Font */
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 1px;
}
