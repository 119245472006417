.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.formMargins {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.title {
  composes: formMargins;
}

.checkInOutTimeBlock {
  display: flex;
  flex-direction: row;
}

.checkInTimeField {
  composes: formMargins;
  flex-shrink: 0;
  width: calc( 50% - 10px );
  margin-right: 10px;
}

.checkOutTimeField {
  composes: formMargins;
  flex-shrink: 0;
  width: calc( 50% - 10px );
  margin-left: 10px;
}

.tenantsBlock {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.tenantsField {
  @media (--viewportMedium) {
    composes: formMargins;
    flex-shrink: 0;
    width: calc( 33% - 10px );
    margin-right: 10px;
  }
}

.childrenField {
  @media (--viewportMedium) {
    composes: formMargins;
    flex-shrink: 0;
    width: calc( 34% - 20px );
    margin: 0 10px;
  }
}

.infantsField {
  @media (--viewportMedium) {
    composes: formMargins;
    flex-shrink: 0;
    width: calc( 33% - 10px );
    margin-left: 10px;
  }
}

.petsBlock {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
    margin-bottom: 32px;
  }
}

.petsSubtitle {
  font-size: 18px;
  font-weight: 600;
}

.catsCheckbox {
  margin-bottom: 0;

  @media (--viewportMedium) {
    composes: formMargins;
    flex-shrink: 0;
    margin-right: 30px;
  }
}

.dogsCheckbox {
  margin-bottom: 0;

  @media (--viewportMedium) {
    composes: formMargins;
    flex-shrink: 0;
    margin-right: 30px;
  }
}

.noPetsCheckbox {
  @media (--viewportMedium) {
    composes: formMargins;
    flex-shrink: 0;
    margin: 0;
  }
}

.rulesBlock {
  display: flex;
  flex-direction: column;
}

.rulesSubtitle {
  font-size: 18px;
  font-weight: 600;
}

.rulesOptions {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.policy {
  composes: formMargins;
  flex-shrink: 0;
}

.error {
  color: var(--failColor);
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}
