@import '../../styles/propertySets.css';

.title {
  @apply --marketplaceH1FontStyles;
  margin-top: 0;
  font-weight: 900;
  text-align: center;
  font-size: 36px;
  line-height: 40px;
  vertical-align: bottom;
}

.logoImage {
  height: 27px;
  width: auto;
  margin-bottom: 5px;
}

.subtitle {
  @apply --marketplaceH2FontStyles;
  margin-top: 0;
  text-align: center;
}

.registeredSign {
  color: red;
}

.steps {
  display: flex;
  flex-direction: column;
  text-align: center;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.step {
  @media (--viewportMedium) {
    width: 324px;
    margin-right: 40px;
  }
}

.step:last-of-type {
  @media (--viewportMedium) {
    margin-right: 0;
  }
}

.stepTitle {
  margin-top: 18px;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    margin-top: 21px;
    margin-bottom: 18px;
  }
}

.createListingLink {
  margin-top: 18px;

  @media (--viewportMedium) {
    margin-top: 24px;
  }
}
