.root {
  /* Dimensions */
  width: 100%;
  height: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.formMargins {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.error {
  color: var(--failColor);
}

.pricingSubtitle {
  font-size: 18px;
  font-weight: 600;
}

.timeUnitBlock {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    flex-direction: row;
    margin-bottom: 16px;
  }
}

.timeUnitRadiobutton {
  margin-bottom: 0;

  @media (--viewportMedium) {
    composes: formMargins;
    flex-shrink: 0;
    margin-right: 30px;
  }
}

.securityDepositField {
  margin-bottom: 12px;

  @media (--viewportMedium) {
    composes: formMargins;
    flex-shrink: 0;
    margin-bottom: 16px;
  }
}

.allowInstallmentsBlock {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    flex-direction: row;
    margin-bottom: 16px;
  }
}

.allowInstallmentsSubtitle {
  margin-top: 16px;
  font-size: 18px;
  font-weight: 600;
}

.allowInstallmentsRadiobutton {
  margin-bottom: 0;

  @media (--viewportMedium) {
    composes: formMargins;
    flex-shrink: 0;
    margin-right: 30px;
  }
}

.extraFeesSubtitle {
  font-size: 18px;
  font-weight: 600;
}

.cleaningFeeField {
  margin-bottom: 12px;

  @media (--viewportMedium) {
    composes: formMargins;
    flex-shrink: 0;
    margin-bottom: 16px;
  }
}

.occupancyTaxField {
  margin-bottom: 12px;

  @media (--viewportMedium) {
    composes: formMargins;
    flex-shrink: 0;
    margin-bottom: 16px;
  }
}

.priceInput {
  flex-shrink: 0;
  width: 100%;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    margin-bottom: 16px;
  }
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}
