@import '../../styles/propertySets.css';

.root {
  margin-top: 24px;
  width: 100%;
  box-shadow: 0 0 5px 5px #EEE;
  padding: 20px;

  @media (--viewportMedium) {
    margin-top: 32px;
  }

  @media (--viewportLarge) {
    max-width: calc( 1024px - 10px );
    margin-left: auto;
    margin-right: auto;
  }
}

.sectionContainer {
  padding: 0;
  margin-bottom: 36px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 56px;
  }
}

.sectionTitle {
  /* Font */
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 12px;
  padding-top: 3px;
  padding-bottom: 3px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 24px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.lastSection {
  margin-bottom: 69px;

  @media (--viewportMedium) {
    margin-bottom: 111px;

    & .sectionTitle {
      margin-bottom: 16px;
    }
  }
}

.uploadAvatarInput {
  display: none;
}

.uploadAvatarWrapper {
  margin-top: 18px;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    margin-top: 44px;
    margin-bottom: 20px;
  }
}

.label {
  width: var(--ProfileSettingsForm_avatarSize);
  @media (--viewportMedium) {
    width: var(--ProfileSettingsForm_avatarSizeDesktop);
  }
}

.avatarPlaceholder,
.avatarContainer {
  /* Dimension */
  position: relative;
  width: var(--ProfileSettingsForm_avatarSize);
  height: var(--ProfileSettingsForm_avatarSize);

  /* Center content */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Initial coloring */
  background-color: var(--matterColorBright);
  border-radius: calc(var(--ProfileSettingsForm_avatarSize) / 2);
  cursor: pointer;

  @media (--viewportMedium) {
    width: var(--ProfileSettingsForm_avatarSizeDesktop);
    height: var(--ProfileSettingsForm_avatarSizeDesktop);
    border-radius: calc(var(--ProfileSettingsForm_avatarSizeDesktop) / 2);
  }
}

.avatarPlaceholder {
  /* Placeholder border */
  border-style: dashed;
  border-color: var(--matterColorNegative);
  border-width: 2px;

  transition: var(--transitionStyleButton);

  &:hover {
    border-color: var(--matterColorAnti);
  }
}

.avatarPlaceholderTextMobile {
  @media (--viewportMedium) {
    display: none;
  }
}
.avatarPlaceholderText {
  display: none;

  @media (--viewportMedium) {
    display: block;
    max-width: 130px;
    text-align: center;
  }
}

.avatarUploadError {
  /* Placeholder border */
  border-style: dashed;
  border-color: var(--failColor);
  border-width: 2px;
}

.error {
  /* Font */
  @apply --marketplaceH4FontStyles;
  color: var(--failColor);
  margin-top: 18px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 22px;
    margin-bottom: 2px;
  }
}

.avatar {
  width: 100%;
  height: 100%;
}

.changeAvatar {
  /* Font */
  @apply --marketplaceH5FontStyles;

  font-weight: var(--fontWeightMedium);

  /* Positioning: right */
  position: absolute;
  bottom: 27px;
  right: -129px;
  /* Dimensions */
  width: 105px;
  height: 41px;
  padding: 11px 10px 7px 35px;

  /* Look and feel (buttonish) */
  background-color: var(--matterColorLight);
  background-image: url('data:image/svg+xml;utf8,<svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"><g stroke="%234A4A4A" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><path d="M5.307 11.155L1 13l1.846-4.308L10.54 1 13 3.46zM11 5L9 3M5 11L3 9"/></g></svg>');
  background-repeat: no-repeat;
  background-position: 15px 12px;
  border: solid 1px var(--matterColorNegative);
  border-radius: 2px;

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    /* Position: under */
    bottom: -10px;
    right: auto;
    margin-top: 0;
    margin-bottom: 0;
    transition: var(--transitionStyleButton);
    padding: 11px 10px 7px 35px;
  }

  &:hover {
    border: solid 1px var(--matterColorAnti);
  }
}

.uploadingImage {
  /* Dimensions */
  width: var(--ProfileSettingsForm_avatarSize);
  height: var(--ProfileSettingsForm_avatarSize);

  /* Image fitted to container */
  object-fit: cover;
  background-color: var(--matterColorNegative); /* Loading BG color */
  border-radius: calc(var(--ProfileSettingsForm_avatarSize) / 2);
  overflow: hidden;

  display: block;
  position: relative;
  margin: 0;

  @media (--viewportMedium) {
    width: var(--ProfileSettingsForm_avatarSizeDesktop);
    height: var(--ProfileSettingsForm_avatarSizeDesktop);
    border-radius: calc(var(--ProfileSettingsForm_avatarSizeDesktop) / 2);
  }
}

.uploadingImageOverlay {
  /* Cover everything (overlay) */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  /* Overlay style */
  background-color: var(--matterColorLight);
  opacity: 0.8;

  /* Center content */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Avatar has square aspect ratio */
/* Default is 3:2 */
.squareAspectRatio {
  padding-bottom: 100%;
}

.avatarInvisible {
  visibility: hidden;
  position: absolute;
  top: -1000px;
  left: -1000px;
}

.tip {
  @apply --marketplaceDefaultFontStyles;
  color: var(--matterColorAnti);
  margin-top: 0;
  margin-bottom: 12px;
  padding-top: 5px;
  padding-bottom: 1px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.fileInfo {
  @apply --marketplaceH5FontStyles;
  color: var(--matterColorAnti);
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 5px;
  padding-bottom: 1px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 6px;
    padding-bottom: 2px;
  }
}

.nameContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;

  @media (--viewportMedium) {
    margin-top: 24px;
  }
}

.firstName {
  width: calc(34% - 9px);
}

.lastName {
  width: calc(66% - 9px);
}

.bioInfo {
  color: var(--matterColorAnti);
  margin-top: 11px;

  @media (--viewportMedium) {
    margin-top: 16px;
  }
}

.submitButton {
  margin-top: 24px;
}

.demandRow {
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.guageCell {
  width: 100%;
  text-align: center;

  @media (--viewportMedium) {
    width: 50%;
  }
}

.demandCell {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;

  @media (--viewportMedium) {
    width: 50%;
  }
}

.demandLevelTitle {
  font-size: 18px;
  width: 100%;
  text-align: center;
  color: #333333;
  margin-top: 25px;
  margin-bottom: 25px;

  @media (--viewportMedium) {
    font-size: 24px;
  }
}

.demandLevelSubtitle {
  font-size: 14px;
  width: 100%;
  text-align: center;
  color: #333333;
  margin-bottom: 25px;

  @media (--viewportMedium) {
    font-size: 18px;
  }
}

.offerRow {
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.offerPriceLevelCellWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
  text-align: center;
  margin-bottom: 20px;

  @media (--viewportMedium) {
    width: 50%;
    margin-right: 25px;
    margin-bottom: 0;
  }
}

.offerPriceLevelTitle {
  font-size: 14px;
  font-weight: 600;
}

.offerPriceInputRow {
  display: flex;
  flex-direction: row;
  font-size: 32px;
}

.offerPriceInputPrefix {
  margin-top: 9px;
  margin-right: 5px;

  @media (--viewportMedium) {
    margin-top: 7px;
  }
}

.offerPriceLevelCell {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.offerPriceLevelLow {
  width: 33%;
  background-color: #3CFC2C;
  text-align: center;
  height: 45px;
  padding-top: 12px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;

  @media (--viewportMedium) {
    padding-top: 8px;
  }
}

.offerPriceLevelMedium {
  width: 34%;
  background-color: #FFFD34;
  text-align: center;
  height: 45px;
  padding-top: 12px;

  @media (--viewportMedium) {
    padding-top: 8px;
  }
}

.offerPriceLevelHigh {
  width: 33%;
  background-color: #FA1418;
  text-align: center;
  height: 45px;
  padding-top: 12px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;

  @media (--viewportMedium) {
    padding-top: 8px;
  }
}

.suggestedPriceOfferCell {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
  text-align: center;

  @media (--viewportMedium) {
    width: 50%;
    margin-top: 0;
    margin-left: 25px;
  }
}

.suggestedPriceOfferInput {
  font-size: 24px;
  height: 45px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 32px;
  }
}

.suggestedPriceOfferInput::before {
  content: '$';
}

.suggestedPriceInputHint {
  font-size: 14px;
}

.bookingDaysRow {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  font-size: 16px;
  text-align: center;
  width: 100%;
  padding-left: auto;
  padding-right: auto;

  @media (--viewportMedium) {
    font-size: 18px;
  }
}

.numberOfDays {
  margin-left: 10px;
  margin-top: 2px;
}
