@import '../../styles/propertySets.css';

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.rootDaily {
  @apply --backgroundDayImage;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-left-width: 0;
  border-right-width: 0;
  border-bottom-width: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  /* Text positioning is done with paddings */
}

.rootNightly {
  @apply --backgroundNightImage;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-left-width: 0;
  border-right-width: 0;
  border-bottom-width: 0;
  border-bottom-right-radius: 0;

  /* Text positioning is done with paddings */
}

.heroContent {
  margin: 0 24px 0 24px;
  text-align: center;

  @media (--viewportMedium) {
    margin: 0 7.5vw 0 7.5vw;
  }

  /* Special viewport for adjusting the heroContent's placement */

  @media only screen and (min-width: 1025px) and (max-width: 1366px) {
    padding: 0 36px 0 36px;
  }

  @media only screen and (min-width: 1367px) {
    margin: 0 auto;
    max-width: calc(1052px + 36px + 36px);
    width: 100%;
    padding: 0 36px 0 36px;
  }
}

.heroMainTitleText {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.heroMainTitle {
  @apply --marketplaceHeroTitleFontStyles;
  color: var(--matterColorLight);
  display: flex;
  flex-direction: row;
  font-family: 'Raleway', sans-serif;
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 900;
  color: #fff;
  text-shadow: 0 0 3px #000;
  margin-bottom: 18px;
  margin-top: 4px;

  composes: animation;
  animation-delay: 0.5s;

  @media (--viewportMedium) {
    max-width: 767;
    font-size: 50px;
    margin-bottom: 16px;
  }

  @media (--viewportLarge) {
    font-size: 60px;
  }
}

.tmMainTitle {
  font-weight: 600;
  font-size: 12px;
  margin-top: -5px;
  margin-left: 5px;

  @media (--viewportMedium) {
    font-size: 20px;
    margin-top: -20px;
    margin-left: 20px;
  }
}

.heroMainSpace {
  width: auto;
  flex-grow: 1;
}

.heroSubTitleText {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.heroSubTitle {
  @apply --marketplaceH4FontStyles;
  font-size: 16px;

  color: var(--matterColorLight);
  margin: 0 0 8px 0;

  composes: animation;
  animation-delay: 0.65s;

  @media (--viewportSmall) {
    margin: 0 0 12px 0;
    font-size: 16px;
  }

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
    margin: 0 0 20px 0;
    font-size: 20px;
  }
}

.rentalTypeBlock {
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;

  @media (--viewportMedium) {
    margin-bottom: 20px;
  }
}

.monthlySelected {
  text-align: center;
  border-width: 0;
  background-color: black;
  color: #C51E24;
  composes: animation;
  border-top-left-radius: 14px;
  border-bottom-left-radius: 14px;

  animation-delay: 0.8s;

  width: 80px;
  height: 30px;
  padding-top: 2px;

  @media (--viewportMedium) {
    width: 150px;
    height: 40px;
    padding-top: 4px;
  }
}

.monthlyUnselected {
  text-align: center;
  border-width: 0;
  background-color: black;
  color: white;
  composes: animation;
  border-top-left-radius: 14px;
  border-bottom-left-radius: 14px;

  animation-delay: 0.8s;

  width: 80px;
  height: 30px;
  padding-top: 2px;

  @media (--viewportMedium) {
    width: 150px;
    height: 40px;
    padding-top: 4px;
  }

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.nightlySelected {
  text-align: center;
  border-width: 0;
  background-color: #808080;
  color: #C51E24;
  composes: animation;
  border-top-right-radius: 14px;
  border-bottom-right-radius: 14px;

  animation-delay: 0.8s;

  width: 80px;
  height: 30px;
  padding-top: 2px;

  @media (--viewportMedium) {
    width: 150px;
    height: 40px;
    padding-top: 4px;
  }
}

.nightlyUnselected {
  text-align: center;
  border-width: 0;
  background-color: #808080;
  color: white;
  composes: animation;
  border-top-right-radius: 14px;
  border-bottom-right-radius: 14px;

  animation-delay: 0.8s;

  width: 80px;
  height: 30px;
  padding-top: 2px;

  @media (--viewportMedium) {
    width: 150px;
    height: 40px;
    padding-top: 4px;
  }

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.heroButtonSpace {
  width: auto;
  flex-grow: 1;
}

.heroButtonWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.heroButton {
  @apply --marketplaceButtonStyles;
  composes: animation;
  background-color: #C51E24;
  width: 160px;
  height: 30px;
  min-height: 30px;
  padding-top: 4px;

  animation-delay: 0.8s;

  @media (--viewportMedium) {
    display: block;
    width: 300px;
    height: 59px;
    min-height: 59px;
    padding-top: 17px;
  }
}
