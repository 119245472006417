@import '../../styles/propertySets.css';

.title {
  @apply --marketplaceH1FontStyles;
  margin-top: 0;
}

.subtitle {
  @apply --marketplaceH2FontStyles;
  margin-top: 0;
}

.registeredSign {
  color: red;
}

.howToTitle {
  @apply --marketplaceH2FontStyles;
  color: white;
  text-align: center;
}

.steps {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  @media (--viewportMedium) {
    flex-direction: row;
    margin-bottom: 40px;
  }
}

.step {
  display: flex;
  flex-direction: column;
  padding: 30px;
  outline: none;
  background-color: white;
  border-color: #9e9e9e;
  box-shadow: 0 0 10px #9e9e9e;
  text-align: center;

  @media (--viewportMedium) {
    flex-direction: row;
    width: 506px;
    margin-right: 40px;
    text-align: left;
  }
}

.step:last-of-type {
  @media (--viewportMedium) {
    margin-right: 0;
  }
}

.stepLeft {
  composes: step;
  margin-bottom: 20px;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.stepTitle {
  font-size: 16px;
  font-weight: 700;
  margin-top: 10px;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.stepText {
  font-size: 14px;
  font-weight: 400;
  margin-top: 10px;
}

.stepNote {
  font-size: 12px;
  font-weight: 700;
  color: red;
}

.cardImageWrapper {
  width: 180px;
  height: 180px;
  margin-left: auto;
  margin-right: auto;

  @media (--viewportMedium) {
    margin-right: 20px;
  }
}

.cardTextWrapper {
  display: flex;
  flex-direction: column;
}

.createListingLink {
  margin-top: 18px;

  @media (--viewportMedium) {
    margin-top: 24px;
  }
}

.actorTypeWrapper {
  display: block;
  width: 100%;
  text-align: center;
}

.actorTypeBlock {
  display: flex;
  flex-direction: row;
  width: 300px;
  margin: 0 auto 36px auto;
  font-size: 20px;
  position: relative;
  top: -25px;

  @media (--viewportMedium) {
    width: 400px;
  }
}

.tenantSelected {
  width: 150px;
  height: 50px;
  text-align: center;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  border-width: 0;
  background-color: black;
  color: white;
  padding-top: 10px;

  @media (--viewportMedium) {
    width: 200px;
  }
}

.tenantUnselected {
  width: 150px;
  height: 50px;
  text-align: center;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  border-width: 0;
  background-color: black;
  color: gray;
  padding-top: 10px;

  @media (--viewportMedium) {
    width: 200px;
  }

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.providerSelected {
  width: 150px;
  height: 50px;
  text-align: center;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  border-style: solid;
  border-color: gray;
  border-width: 0 0 0 1px;
  background-color: black;
  color: white;
  padding-top: 10px;

  @media (--viewportMedium) {
    width: 200px;
  }
}

.providerUnselected {
  width: 150px;
  height: 50px;
  text-align: center;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  border-style: solid;
  border-color: gray;
  border-width: 0 0 0 1px;
  background-color: black;
  color: gray;
  padding-top: 10px;

  @media (--viewportMedium) {
    width: 200px;
  }

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.actionBlock {
  @apply --marketplaceH3FontStyles;
  text-align: center;
}

.searchButton {
  display: block;
  background-color: white;
  color: #E9242C;
  height: 50px;
  border-radius: 25px;
  padding-top: 12px;

  @media (--viewportMedium) {
    width: 250px;
    margin: 0 auto;
  }

  &:hover {
    text-decoration: none;
    cursor: pointer;
    background-color: black;
  }
}

.listButton {
  display: block;
  background-color: white;
  color: #E9242C;
  height: 50px;
  border-radius: 25px;
  padding-top: 12px;

  @media (--viewportMedium) {
    width: 250px;
    margin: 0 auto;
  }

  &:hover {
     text-decoration: none;
     cursor: pointer;
     background-color: black;
  }
}
