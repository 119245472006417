@import '../../styles/propertySets.css';

.root {

}

.imageWrapper {
  display: block;
  width: 100%;
}

.shotImage {
  width: 100%;
  object-fit: contain;
}

.videoPlaybackButton {
  z-index: 100;
  position: relative;
  left: calc(50% - 30px);
  top: 115px;
  color: white;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  border-style: solid;
  border-width: 1px;
  border-color: white;
  text-align: center;
  padding-left: 7px;
  padding-top: 14px;
  font-size: 40px;

  @media screen and (min-width: 400px) {
    top: 125px;
  }

  @media screen and (min-width: 450px) {
    top: 135px;
  }

  @media screen and (min-width: 500px) {
    top: 150px;
  }

  @media screen and (min-width: 550px) {
    top: 160px;
  }

  @media screen and (min-width: 600px) {
    top: 170px;
  }

  @media screen and (min-width: 650px) {
    top: 190px;
  }

  @media screen and (min-width: 700px) {
    top: 205px;
  }

  @media (--viewportMedium) {
    left: calc(50% - 50px);
    top: 235px;
    width: 100px;
    height: 100px;
    border-radius: 50px;
    padding-top: 34px;
    padding-left: 10px;
    font-size: 50px;
  }

  @media screen and (min-width: 800px) {
    top: 245px;
  }

  @media screen and (min-width: 850px) {
    top: 260px;
  }

  @media screen and (min-width: 900px) {
    top: 275px;
  }

  @media screen and (min-width: 950px) {
    top: 290px;
  }

  @media screen and (min-width: 1000px) {
    top: 300px;
  }

  @media screen and (min-width: 1050px) {
    top: 305px;
  }

  @media screen and (min-width: 1100px) {
    top: 320px;
  }

  &:hover {
    cursor: pointer;
  }
}

.playbackContainer {
  position: relative;
  height: 100vh;
  width: 100vw;

  @media (--viewportMedium) {
    height: 60vh;
    width: 80vw;
  }
}

.videoJs {
  width: 100%;
  height: 100%;
}
